<style lang="scss">
#debug {
  display: none;
}
#frontend {
  .service-all-bg {
    position: relative;
    &:before {
      content: '';
      display: block;
      width: 100%;
      height: 52%;
      background: #f6f6f6;
      position: absolute;
      left: 0;
      top: 28%;
      z-index: -1;
      @media (max-width: $width-md) {
        top: 15%;
        height: 65%;
      }
    }
    
  }
  .service-all {
      position: relative;

      .abo-box-inner {
        position: relative;
        left: 100px;
        margin-bottom: -60px;

        @media (max-width: $width-xl) {
          left: 50px;
        }
        @media (max-width: $width-lg) {
          left: 0px;
          margin-bottom: 40px;
        }
      }

      .img-v1 {
        position: relative;
        left: 0px;
        top: -65px;
        max-width: 80%;
        @media (max-width: $width-md) {
          max-width: 100%;
          left: 0;
          top: 0;
          display: block;
          margin: 20px auto;
        }
      }
      .img-v2 {
        position: relative;
        top: -40px;
        margin-bottom: 100px;
        max-width: 70%;
        left: 97px;
        @media (max-width: 1400px) {
          top: -30px;
        }
        @media (max-width: $width-xl) {
          top: -10px;
        }
        @media (max-width: $width-lg) {
          top: 0px;
          left: 99px;
        }

        @media (max-width: $width-md) {
          top: 0;
          left: 0;
          margin-bottom: 0;
          max-width: 100%;
          display: block;
          margin: 20px auto;
        }
      }
      .img-v3 {
        position: relative;
        top: -40px;
        left: -60px;

        @media (max-width: $width-lg) {
          left: 0;
        }
        @media (max-width: $width-md) {
          top: 0;
          left: 0;
          display: block;
          margin: 20px auto;
        }
      }
      .img-v4 {
        @media (max-width: $width-md) {
          display: block;
          margin: 20px auto;
        }
      }

      .img-v5 {
        max-width: 70%;
        position: relative;
        margin: 0 auto;
        display: block;
        left: -218px;
        top: -180px;
        //top:-170px;
        @media (max-width: 1400px) {
          left: -196px;
          top: -155px;
        }
        @media (max-width: $width-xl) {
          left: -176px;
          top: -120px;
        }
        @media (max-width: $width-lg) {
          left: -189px;
          top: -100px;
        }
        @media (max-width: $width-lg) {
          left: -130px;
        }
        @media (max-width: $width-md) {
          top: 0;
          max-width: 100%;
          left: 0;
          display: block;
          margin: 20px auto;
        }
      }

      .bg-violet {
        @media (max-width: $width-md) {
          margin-top: 80px;
        }
      }

      .form-group {
        margin-bottom: 20px;
      }
      
    }
  .person-box-service {
      position:relative;
      margin-bottom:50px;
    
      .txt-box {
        text-align:left;
        padding:60px 25px;
        max-width:100%;
        margin-left:auto;
        margin-right:auto;
        .name {
          font-family: 'Helvetica Neue LT W05_75 Bold';
          font-size:200%;
          @media(max-width:$width-lg) {
            font-size:150%;
          }
          @media(max-width:$width-sm) {
            font-size:130%;
          }
        }
        .job {
          margin-top:20px;
          margin-bottom:20px;
          letter-spacing: 1px;
          padding-right:40px;

          strong {
            display:block;
            font-weight: normal;
            font-family: 'Helvetica Neue LT W05 65 Medium'
          }
          span {
            display:block;
            font-size:90%;
            line-height:1.8;
          }
        }
        .zitat {
          font-family: 'American Typewriter ITC W04 Md';
    
          margin-bottom:40px;
          font-size:110%;
           @media(max-width:$width-sm) {
            font-size:100%;
          }
          .zitat-icon {
            display:block;
          }
        }
        
      }
      .box-middle {
        border:1px solid #878787;
      }
      .box-bottom {
        text-align:left;
        border:1px solid #878787;
        border-top:0;
        padding:20px 25px;
        a {
          display:block;
          letter-spacing: 1px;
          font-size:90%;
          position:relative;
          .icon-img {
            position:absolute;
            right:0;
            top:0;
            img { 
              width:30px;
              height:auto;
              
            }
          }
          &:hover {
            color:#000;
          }
        }
      }
      }
}
</style>

<template>
  <div class="service-all service-all-bg">
    <SocialMediaIconBox />
    <div class="container">
      <div class="row">
        <div class="col-12">
          <div class="headline-box-std">
            <div class="headline-inner">
              <div class="breadcrumb">
                <span>{{ $t('General.service') }}</span>
              </div>
              <h1 v-html="$t('service.aboservice-werbeloesung')"></h1>
            </div>
          </div>
        </div>
      </div>
      
    </div>
    <div class="bg-grey-outer">
        <div class="container">
          <div class="abo-box-inner">
            <div class="row justify-content-center align-self-center">
              <div class="col-lg-5 col-md-6 col-sm-8 col-9">
                <img src="@/assets/img/startseite/aboservice-v6.jpg" alt="Presse" class="img-fluid img-v4" />
              </div>
              <div class="col-lg-5 col-md-6 col-sm-8 col-9 align-self-end">
                <img src="@/assets/img/startseite/aboservice-v1-v2.jpg" alt="SPORTaktiv" class="img-fluid img-v1" />
              </div>
            </div>
            <div class="row align-self-center justify-content-center">
              <div class="col-lg-5 col-md-6 col-sm-8 col-9 justify-content-end align-self-center text-align-right" v-inview="'move-in-from-left'">
                <img src="@/assets/img/startseite/aboservice-v2.jpg" alt="Kleine Zeitung" class="img-fluid img-v2" />
              </div>
              <div class="col-lg-5 col-md-6 col-sm-8 col-9 align-self-end" v-inview="'move-in-from-right'">
                <img src="@/assets/img/startseite/aboservice-v3.jpg" alt="Diva Wienerin" class="img-fluid img-v3" />
              </div>
            </div>
            <div class="row align-self-center text-align-right justify-content-center">
              <div class="col-lg-4 col-md-6 col-sm-8 col-9 justify-content-end">
                <img src="@/assets/img/startseite/aboservice-v4.jpg" alt="Beauty Style" class="img-fluid img-v5" />
              </div>
            </div>
          </div>
        </div>
      </div>
  </div>
  <div class="container-fluid bg-violet">
    <div class="row justify-content-center">
      <div class="col-lg-5 col-md-7 padding-tb-l">
        <h2 class="color-fff text-center margin-b-s">{{ $t('service.willkommen-im-netzwerk') }}</h2>
        <p class="intro-txt text-center color-fff ">{{ $t('service.willkommen-im-netzwerk-intro-txt') }}</p>
      </div>
    </div>
  </div>
  <div class="container padding-tb-m">
    <div class="row justify-content-center">
      <div class="col-12 padding-tb-m">
        <h3 class="text-center" v-html="$t('General.wir-freuen-uns-auf-sie') "></h3>
      </div>
      <div class="row justify-content-center">
        <div class="col-lg-8" v-if="!sent">
          <!-- -->
          <div class="row">
            <div class="col-lg-6">
              <div class="form-group">
                <div class="label-input">{{ $t('General.ich-bin-kuenftiger') }}</div>
                <select class="form-select" v-model="iam">
                  <option :value="0">{{ $t('General.bitte-waehlen') }} </option>
                  <option :value="1">{{ $t('General.abonnentin') }} </option>
                  <option :value="2">{{ $t('General.werbekundin') }} </option>
                </select>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="form-group" v-if="selectedCompanies.length > 0">
                <div class="label-input">{{ $t('General.es-geht-um-die-marke') }}</div>
                <select class="form-select" v-model="company">
                  <option :value="0">{{ $t('General.bitte-waehlen') }}</option>
                  <option :value="company.id" :key="company.id" v-for="company of selectedCompanies">{{ company.name }}</option>
                </select>
              </div>
            </div>

            <div class="col-lg-6">
              <div class="form-group">
                <div class="label-input">{{ $t('General.form-vorname') }}</div>
                <input type="text" v-model="firstname" />
              </div>
            </div>
            <div class="col-lg-6">
              <div class="form-group">
                <div class="label-input">{{ $t('General.form-nachname') }}</div>
                <input type="text" v-model="surname" />
              </div>
            </div>
          </div>
          <div class="col-lg-12">
            <div class="form-group">
              <div class="label-input">{{ $t('General.form-e-mail') }}</div>
              <input type="text" v-model="email" />
            </div>
          </div>
          <div class="col-lg-12">
            <div class="form-group">
              <div class="label-input">{{ $t('General.form-wie-koennen-wir-helfen') }}</div>
              <textarea v-model="text"></textarea>
            </div>
          </div>

          <div class="col-lg-12">
            <a :href="path" target="_blank" v-html="$t('General.datenschutz-form-txt')" class="link-form-datenschutz"></a>
          </div>

          <div class="col-lg-4 col-md-10 margin-t-s">
            <button class="btn-formular" @click="send">{{ $t('General.btn-senden') }}</button>
          </div>

          <div class="row justify-content-center" v-if="error && error != ''">
            <div class="col-lg-12 col-md-10 text-center">
              {{ error }}
            </div>
          </div>
        </div>

        <div class="col-lg-8 text-center" v-if="sent">
          {{ $t('General.email-success') }}
        </div>
        <!-- -->
      </div>
    </div>
  </div>
  <div class="service-all">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-12">
          <div class="headline-box-std">
            <div class="headline-inner">
              <h1 v-html="$t('service.werben-ohne-grenzen-txt')"></h1>
            </div>
          </div>
        </div>
        <div class="col-lg-4">
          <div class="person-box-service">
            <img src="@/assets/img/service/alexandera-kalcher.jpg" alt="Alexandra Kalcher" class="img-fluid" />
            <div class="box-middle">
              <div class="txt-box">
                <div class="name">Alexandra Kalcher</div>
                <div class="job">
                  <strong>{{ $t('service.alexandra-kalcher-jobtitle') }}</strong>
                  <span>Styria Media International</span>
                </div>
                <div class="zitat"><Icon :path="mdiFormatQuoteClose" class="zitat-icon" />{{ $t('service.alexandra-kalcher-zitat') }}</div>
              </div>
            </div>
            <div class="box-bottom">
              <a href="mailto:alexandra.kalcher@styria.com"
                >alexandra.kalcher@styria.com<span class="icon-img"><img src="@/assets/img/header-kontakt-icon.svg" alt="Kontakt" class="img-fluid"/></span
              ></a>
            </div>
          </div>
          
        </div>
      </div>
    </div>
  </div>
  <Footer />
</template>

<script lang="ts">
import { computed, defineComponent, ref, watch } from 'vue';

import SocialMediaIconBox from '../../components/Frontend/SocialMediaIconBox.vue';
import Footer from '../../components/Frontend/Footer.vue';
// import Slider from '../../components/Slider.vue';
// import SliderItem from '../../components/SliderItem.vue';
// import { mdiChevronUp } from '@mdi/js';
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';
import { mdiFormatQuoteClose } from '@mdi/js';

export default defineComponent({
  meta: {
    de: {
      title: 'Aboservice und Werbelösungen | Styria Media Group',
      keywords: ['service', 'aboservice', 'abo', 'werbelösung', 'werbung', 'styria'],
      description: 'Haben Fragen zu Ihrem Abo oder wollen neuen Kontakte als Werbekunde knüpfen? Wir freuen uns auf Sie.',
      image: require('@/assets/img/og/service.png'),
    },
    en: {
      title: 'subscription and andvertising | Styria Media Group',
      keywords: ['service', 'subscription', 'advertising', 'ads', 'styria'],
      description: 'Do you have questions about your subscription or would you like to make new contacts as an advertising customer? We look forward to hearing from you.',
      image: require('@/assets/img/og/service.png'),
    },
  },
  components: {
    SocialMediaIconBox,

    //Slider,
    //SliderItem,
    Footer,
  },
  setup() {
    const iam = ref(0);
    const company = ref(0);
    const companies = {
      1: [
        { name: 'DIVA', id: 6 },
        { name: 'Die Furche', id: 2 },
        { name: 'Die Presse', id: 3 },
        { name: 'Kleine Kinderzeitung', id: 7 },
        { name: 'Kleine Zeitung', id: 4 },
        { name: 'Pausenzeitung', id: 8 },
        { name: 'SPORTaktiv', id: 1 },
        { name: 'WIENERIN', id: 5 },
      ],
      2: [
        { name: 'Content Performance Group', id: 9 },
        { name: 'DIVA', id: 6 },
        { name: 'Die Furche', id: 2 },
        { name: 'Die Presse', id: 3 },
        { name: 'ichkoche.at', id: 10 },
        { name: 'Kleine Kinderzeitung', id: 7 },
        { name: 'Kleine Zeitung', id: 4 },
        { name: 'Pausenzeitung', id: 8 },
        { name: 'SPORTaktiv', id: 1 },
        { name: 'WIENERIN', id: 5 },
      ],
    };

    const selectedCompanies = computed(() => {
      if (iam.value == 1 || iam.value == 2) {
        return companies[iam.value];
      }
      return [];
    });

    watch(iam, () => {
      company.value = 0;
    });

    const store = useStore();
    const i18n = useI18n();
    const firstname = ref('');
    const surname = ref('');
    const text = ref('');
    const email = ref('');
    const dsvgo = ref(false);
    const error = ref('');

    const path = `${store.getters.getHomeBase}datenschutz_${i18n.locale.value}.html`;
    const sent = ref(false);
    const send = () => {
      error.value = '';

      if (!company.value || company.value == 0) {
        error.value = i18n.t('General.formerror-company');
        return;
      }

      if (!iam.value || iam.value == 0) {
        error.value = i18n.t('General.formerror-iam');
        return;
      }

      if (!text.value || text.value == '') {
        error.value = i18n.t('General.formerror-firstname');
        return;
      }

      if (!surname.value || surname.value == '') {
        error.value = i18n.t('General.formerror-surname');
        return;
      }
      if (!email.value || email.value == '') {
        error.value = i18n.t('General.formerror-email');
        return;
      }
      if (!text.value || text.value == '') {
        error.value = i18n.t('General.formerror-text');
        return;
      }
      if (error.value == '') {
        store
          .dispatch('sendMail', {
            type: 'service',
            locale: i18n.locale.value,
            data: {
              type: iam.value,
              company: company.value,
              firstname: firstname.value,
              surname: surname.value,
              text: text.value,
              email: email.value,
            },
          })
          .then(() => {
            sent.value = true;
          });
      }
      return;
    };

    return {
      sent,
      firstname,
      surname,
      text,
      email,
      error,
      send,
      iam,
      company,
      selectedCompanies,
      mdiFormatQuoteClose,
      path,
    };
  },
});
</script>
